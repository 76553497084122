import React from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";

const CUSTOM_ANIMATION = {
  mount: { scale: 1 },
  unmount: { scale: 0.9 },
};

function Icon({ id, open }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className={`${
        id === open ? "rotate-180" : ""
      } h-5 w-5 transition-transform`}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
      />
    </svg>
  );
}

export function AccordionCustomAnimation() {
  const [open, setOpen] = React.useState(0);

  const handleOpen = (value) => setOpen(open === value ? 0 : value);

  return (
    <>
      <Accordion
        open={open === 1}
        animate={CUSTOM_ANIMATION}
        className="mx-auto max-w-7xl bg-[#951514] p-4"
        icon={<Icon id={1} open={open} />}
      >
        <AccordionHeader
          onClick={() => handleOpen(1)}
          className="text-white hover:text-[#e8d3d3]"
        >
          NOSSO FOCO
        </AccordionHeader>
        <AccordionBody className="text-white">
          Nossas principais atividades envolvem a comunicação, intercomunicação
          e radiocomunicação, com um foco constante na qualidade e precisão
          técnica. Entendemos que, para oferecer serviços de excelência, é
          essencial manter um alto padrão de rigor em todas as etapas dos nossos
          processos. Por isso, investimos continuamente em tecnologia de ponta e
          na capacitação da nossa equipe, garantindo que cada projeto seja
          executado com a máxima eficiência e precisão. <br />
          <br />
          Além disso, a pontualidade e o compromisso com a excelência são
          pilares fundamentais que sustentam nossa atuação no mercado. Sabemos
          que a confiança dos nossos clientes é construída a partir de entregas
          consistentes e de alta qualidade, por isso, estamos sempre prontos
          para superar expectativas e atender às necessidades específicas de
          cada projeto. Nossa dedicação à melhoria contínua e à inovação reflete
          nosso compromisso em ser um parceiro de confiança em todas as
          iniciativas de comunicação e radiocomunicação.
        </AccordionBody>
      </Accordion>
      <Accordion
        open={open === 2}
        animate={CUSTOM_ANIMATION}
        className="mx-auto max-w-7xl bg-[#951514] p-4"
        icon={<Icon id={2} open={open} />}
      >
        <AccordionHeader
          onClick={() => handleOpen(2)}
          className="text-white hover:text-[#e8d3d3]"
        >
          NOSSOS VALORES
        </AccordionHeader>
        <AccordionBody className="text-white">
          Os valores que norteiam nossa empresa são fundamentais para todas as
          nossas decisões e práticas diárias. Eles garantem que cada ação seja
          guiada por princípios sólidos de integridade, compromisso e inovação.
          Através desses valores, asseguramos que nossos clientes recebam
          soluções e equipamentos consistentes e eficazes, que atendam e superem
          suas expectativas. Cada projeto é uma oportunidade de demonstrar nosso
          compromisso com a excelência e a qualidade, sempre buscando oferecer o
          melhor em tecnologia e atendimento. <br />
          <br />
          Estamos constantemente comprometidos em manter um ambiente de trabalho
          ético e colaborativo, onde a confiança e o respeito mútuo sejam a base
          para todas as relações, tanto internas quanto externas. Nossos valores
          nos impulsionam a inovar e melhorar continuamente, garantindo que
          nossos produtos e serviços sejam sempre os mais avançados e confiáveis
          do mercado. Dessa forma, construímos parcerias duradouras e entregamos
          resultados excepcionais que refletem o nosso compromisso com a
          excelência e a satisfação do cliente.
        </AccordionBody>
      </Accordion>
      <Accordion
        open={open === 3}
        animate={CUSTOM_ANIMATION}
        className="mx-auto max-w-7xl bg-[#951514] p-4"
        icon={<Icon id={3} open={open} />}
      >
        <AccordionHeader
          onClick={() => handleOpen(3)}
          className="text-white hover:text-[#e8d3d3]"
        >
          NOSSA EQUIPE
        </AccordionHeader>
        <AccordionBody className="text-white">
          Nossa equipe é composta por profissionais altamente qualificados, que
          trazem consigo uma vasta experiência e conhecimento nas áreas de
          comunicação, intercomunicação e radiocomunicação. Cada membro é
          cuidadosamente selecionado não apenas pela sua competência técnica,
          mas também pelo seu comprometimento com a excelência e a satisfação do
          cliente. Estamos sempre prontos para atender com eficiência e precisão
          as demandas dos nossos clientes, garantindo que cada projeto seja
          executado com o mais alto padrão de qualidade. <br />
          <br />
          Além de suas habilidades técnicas, nossos profissionais são dedicados
          e apaixonados pelo que fazem, o que se reflete na atenção aos detalhes
          e na busca constante por soluções inovadoras. Eles trabalham de
          maneira colaborativa, unindo esforços e conhecimentos para entregar
          resultados que superem as expectativas. Nosso compromisso com o
          desenvolvimento contínuo da equipe assegura que estamos sempre à
          frente, prontos para enfrentar novos desafios e oferecer o melhor em
          termos de tecnologia e serviço.
        </AccordionBody>
      </Accordion>
    </>
  );
}
