import { Carousel, IconButton } from "@material-tailwind/react";

export function HeaderCarousel() {
  return (
    <>
      <Carousel
        className="relative mx-auto max-h-[35em] max-w-[86em] overflow-hidden "
        autoplay={true}
        interval={4000}
        loop={true}
        prevArrow={({ handlePrev }) => (
          <IconButton
            variant="text"
            size="lg"
            onClick={handlePrev}
            className="!absolute top-2/4 left-0 -translate-y-2/4 text-[#951514] hover:bg-[#e8d3d3] hover:text-[#951514]"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="h-6 w-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
              />
            </svg>
          </IconButton>
        )}
        nextArrow={({ handleNext }) => (
          <IconButton
            variant="text"
            size="lg"
            onClick={handleNext}
            className="!absolute top-2/4 !right-0 -translate-y-2/4 text-[#951514] hover:bg-[#e8d3d3] hover:text-[#951514]"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="h-6 w-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3"
              />
            </svg>
          </IconButton>
        )}
        navigation={({ setActiveIndex, activeIndex, length }) => (
          <div className="absolute bottom-4 left-2/4 z-50 flex -translate-x-2/4 gap-2">
            {new Array(length).fill("").map((_, i) => (
              <span
                key={i}
                className={`block h-1 cursor-pointer rounded-2xl transition-all content-[''] ${
                  activeIndex === i ? "w-8 bg-[#951514]" : "w-4 bg-[#e8d3d3]"
                }`}
                onClick={() => setActiveIndex(i)}
              />
            ))}
          </div>
        )}
      >
        <section className="relative">
          <div className="container mx-auto flex items-center justify-between px-6 py-12 lg:py-24">
            {/* Texto */}
            <div className="lg:w-1/2">
              <h1 className="text-4xl lg:text-5xl font-bold text-black leading-tight">
                Qual o tempo de resposta ideal para{" "}
                <span className="text-[#951514]">
                  atendimento de uma emergência?
                </span>
              </h1>
              <p className="mt-6 text-gray-600 text-2xl">
                Portfólio de soluções que irão apoiar a equipe de emergência de
                sua empresa a salvar vidas.
              </p>
              <button className="mt-8 px-8 py-4 bg-[#951514] text-white font-semibold rounded-md hover:bg-[#ac2a2a] transition duration-300">
                + SAIBA MAIS
              </button>
            </div>

            {/* Imagem */}
            <div className="lg:w-1/2 lg:flex lg:justify-end hidden">
              <img
                src={"/assets/images/banner-1.webp"}
                alt="Emergency Response"
                className="w-full lg:w-auto h-auto max-h-[24rem] object-cover"
              />
            </div>
          </div>

          {/* Background pattern */}
          <div className="absolute top-0 left-0 w-full bg-gradient-to-r from-transparent via-white to-transparent pointer-events-none"></div>
        </section>

        <section className="relative">
          <div className="container mx-auto flex items-center justify-between px-6 py-12 lg:py-24">
            {/* Texto */}
            <div className="lg:w-1/2">
              <h1 className="text-4xl lg:text-5xl font-bold text-black leading-tight">
                Qual o tempo de resposta ideal para{" "}
                <span className="text-[#951514]">
                  atendimento de uma emergência?
                </span>
              </h1>
              <p className="mt-6 text-gray-600 text-2xl">
                Portfólio de soluções que irão apoiar a equipe de emergência de
                sua empresa a salvar vidas.
              </p>
              <button className="mt-8 px-8 py-4 bg-[#951514] text-white font-semibold rounded-md hover:bg-[#ac2a2a] transition duration-300">
                + SAIBA MAIS
              </button>
            </div>

            {/* Imagem */}
            <div className="lg:w-1/2 lg:flex lg:justify-end hidden">
              <img
                src={"/assets/images/banner-2.webp"}
                alt="Emergency Response"
                className="w-full lg:w-auto h-auto max-h-[24rem] object-cover"
              />
            </div>
          </div>

          {/* Background pattern */}
          <div className="absolute top-0 left-0 w-full bg-gradient-to-r from-transparent via-white to-transparent pointer-events-none"></div>
        </section>

        <section className="relative">
          <div className="container mx-auto flex items-center justify-between px-6 py-12 lg:py-24">
            {/* Texto */}
            <div className="lg:w-1/2">
              <h1 className="text-4xl lg:text-5xl font-bold text-black leading-tight">
                Qual o tempo de resposta ideal para{" "}
                <span className="text-[#951514]">
                  atendimento de uma emergência?
                </span>
              </h1>
              <p className="mt-6 text-gray-600 text-2xl">
                Portfólio de soluções que irão apoiar a equipe de emergência de
                sua empresa a salvar vidas.
              </p>
              <button className="mt-8 px-8 py-4 bg-[#951514] text-white font-semibold rounded-md hover:bg-[#ac2a2a] transition duration-300">
                + SAIBA MAIS
              </button>
            </div>

            {/* Imagem */}
            <div className="lg:w-1/2 lg:flex lg:justify-end hidden">
              <img
                src={"/assets/images/banner-2.webp"}
                alt="Emergency Response"
                className="w-full lg:w-auto h-auto max-h-[24rem] object-cover"
              />
            </div>
          </div>

          {/* Background pattern */}
          <div className="absolute top-0 left-0 w-full bg-gradient-to-r from-transparent via-white to-transparent pointer-events-none"></div>
        </section>
        {/* <img
          src={SecondBannerImage}
          alt="image 2"
          className="w-full object-fill"
        /> */}
      </Carousel>
    </>
  );
}
