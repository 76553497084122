import React from "react";
import {
  Navbar,
  Collapse,
  Typography,
  IconButton,
  List,
  ListItem,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from "@material-tailwind/react";
import {
  ChevronDownIcon,
  Bars3Icon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

function NavListMenu() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);

  return (
    <React.Fragment>
      <Menu
        open={isMenuOpen}
        handler={setIsMenuOpen}
        placement="bottom"
        allowHover={true}
      >
        <MenuHandler>
          <Typography as="div" variant="semibold" className="font-semibold">
            <Link to="/produtos">
              <ListItem
                className="flex items-center gap-2 py-2 pr-4 font-semibold text-[#951514] hover:bg-[#e8d3d3] active:bg-[#e8d3d3] hover:text-[#951514] rounded-none"
                selected={isMenuOpen || isMobileMenuOpen}
                onClick={() => setIsMobileMenuOpen((cur) => !cur)}
              >
                Produtos
                <ChevronDownIcon
                  strokeWidth={2.5}
                  className={`hidden h-3 w-3 transition-transform lg:block ${
                    isMenuOpen ? "rotate-180" : ""
                  }`}
                />
                <ChevronDownIcon
                  strokeWidth={2.5}
                  className={`block h-3 w-3 transition-transform lg:hidden ${
                    isMobileMenuOpen ? "rotate-180" : ""
                  }`}
                />
              </ListItem>
            </Link>
          </Typography>
        </MenuHandler>
        <MenuList className="hidden lg:block">
          <Link to="/produtos/DMR">
            <MenuItem className="text-[#951514] hover:bg-[#e8d3d3] active:bg-[#e8d3d3] hover:text-[#951514] rounded-none">
              DMR
            </MenuItem>
          </Link>
          <Link to="/produtos/P25">
            <MenuItem className="text-[#951514] hover:bg-[#e8d3d3] active:bg-[#e8d3d3] hover:text-[#951514] rounded-none">
              P25
            </MenuItem>
          </Link>
        </MenuList>
      </Menu>
      <div className="block lg:hidden">
        <Collapse open={isMobileMenuOpen}>
          <Link to="/produtos/DMR">
            <MenuItem className="text-[#951514] hover:bg-[#e8d3d3] active:bg-[#e8d3d3] hover:text-[#951514] rounded-none">
              DMR
            </MenuItem>
          </Link>
          <Link to="/produtos/P25">
            <MenuItem className="text-[#951514] hover:bg-[#e8d3d3] active:bg-[#e8d3d3] hover:text-[#951514] rounded-none">
              P25
            </MenuItem>
          </Link>
        </Collapse>
      </div>
    </React.Fragment>
  );
}

function NavList() {
  return (
    <List className="mt-4 mb-6 p-0 lg:mt-0 lg:mb-0 lg:flex-row lg:p-1">
      <Typography
        as="a"
        href="#"
        variant="semibold"
        className="font-semibold hover:bg-[#e8d3d3] active:bg-[#e8d3d3] hover:text-[#951514] rounded-none"
      >
        <Link to="/">
          <ListItem className="flex items-center gap-2 py-2 pr-4 font-semibold text-[#951514] hover:bg-[#e8d3d3] active:bg-[#e8d3d3] hover:text-[#951514]">
            Home
          </ListItem>
        </Link>
      </Typography>
      <NavListMenu />
      <Typography
        as="a"
        href="#"
        variant="semibold"
        className="font-semibold hover:bg-[#e8d3d3] active:bg-[#e8d3d3] hover:text-[#951514] rounded-none"
      >
        <Link to="/orcamento">
          <ListItem className="flex items-center gap-2 py-2 pr-4 font-semibold text-[#951514] hover:bg-[#e8d3d3] active:bg-[#e8d3d3] hover:text-[#951514]">
            Orçamento
          </ListItem>
        </Link>
      </Typography>
      <Typography
        as="a"
        href="#"
        variant="semibold"
        className="font-semibold hover:bg-[#e8d3d3] active:bg-[#e8d3d3] hover:text-[#951514] rounded-none"
      >
        <Link to="/contatos">
          <ListItem className="flex items-center gap-2 py-2 pr-4 font-semibold text-[#951514] hover:bg-[#e8d3d3] active:bg-[#e8d3d3] hover:text-[#951514]">
            Contatos
          </ListItem>
        </Link>
      </Typography>
    </List>
  );
}

export function NavbarWithMegaMenu() {
  const [openNav, setOpenNav] = React.useState(false);

  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
  }, []);

  return (
    <Navbar className="mx-auto w-full px-4 py-2 border-none shadow-none">
      <div className="flex items-center justify-start text-[#951514]">
        <Typography
          as="a"
          href="#"
          variant="h6"
          className="mr-4 cursor-pointer py-1.5 lg:ml-2"
        >
          <Link to="/">
            <img
              src={"/assets/images/logo.png"}
              alt="logo"
              className="max-w-72"
            />
          </Link>
        </Typography>
        <div className="hidden lg:block">
          <NavList />
        </div>
        <IconButton
          variant="text"
          color="blue-gray"
          className="lg:hidden"
          onClick={() => setOpenNav(!openNav)}
        >
          {openNav ? (
            <XMarkIcon className="h-6 w-6" strokeWidth={2} />
          ) : (
            <Bars3Icon className="h-6 w-6" strokeWidth={2} />
          )}
        </IconButton>
      </div>
      <Collapse open={openNav}>
        <NavList />
      </Collapse>
    </Navbar>
  );
}
