import React from "react";
import { motion } from "framer-motion";

function Location() {
  return (
    <section className="mt-20 pb-20 px-4 ">
      <span id="ubication" className="anchor"></span>
      <div className="flex flex-col items-center gap-5 m-auto max-w-[80em] ">
        <motion.p
          initial={{ translateY: 200 }}
          whileInView={{ translateY: 0 }}
          transition={{ duration: 0.5, ease: "easeInOut" }}
          viewport={{ once: true }}
          className="text-xl lg:text-2xl xl:text-3xl font-bold p-3 mb-10 text-center"
          style={{ borderColor: "#951514", color: "#951514" }}
        >
          Onde estamos localizados?
        </motion.p>
        <motion.div
          initial={{ translateY: 200 }}
          whileInView={{ translateY: 0 }}
          transition={{ duration: 0.5, ease: "easeInOut" }}
          viewport={{ once: true }}
          className="flex flex-col md:gap-10 md:flex-row lg:gap-20 justify-around items-center w-full"
        >
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d2218.1721025630363!2d-47.93366435858816!3d-15.760760139678183!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1spt-BR!2sbr!4v1725240799114!5m2!1spt-BR!2sbr"
            width="100%"
            height="450"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            className="rounded-lg shadow-lg"
          ></iframe>
        </motion.div>
      </div>
    </section>
  );
}

export default Location;
