import React from "react";

export function ProductDetailsGallery({ images }) {
  const [active, setActive] = React.useState(images[0]);

  return (
    <div className="grid gap-4">
      <div>
        <img
          className="h-auto w-full max-w-full rounded-lg object-cover object-center md:h-[480px]"
          src={active}
          alt=""
        />
      </div>
      <div className="flex justify-between">
        {images.map((imgelink, index) => (
          <div key={index}>
            <img
              onClick={() => setActive(imgelink)}
              src={imgelink}
              className="h-20 max-w-full cursor-pointer rounded-lg object-cover object-center hover:border-2 hover:border-blue-500 active:border-2 active:border-blue-700"
              alt="gallery-image"
            />
          </div>
        ))}
      </div>
    </div>
  );
}
