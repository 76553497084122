import React from "react";
import { HeaderCarousel } from "../components/HeaderCarousel";
import { AccordionCustomAnimation } from "../components/Accordion";
import { InfoSection } from "../components/InfoSection";
import { motion } from "framer-motion";
import AboutUs from "../components/AboutUs";
import Location from "../components/Location";

const Home = () => {
  return (
    <div className="bg-gray-100">
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1.5, ease: "easeOut" }}
      >
        <HeaderCarousel />
      </motion.div>
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1.5, delay: 0.2, ease: "easeOut" }}
      >
        <InfoSection />
      </motion.div>
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1.5, delay: 0.4, ease: "easeOut" }}
      >
        <AboutUs />
      </motion.div>
      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1.5, delay: 0.4, ease: "easeOut" }}
      >
        <AccordionCustomAnimation />
      </motion.div>
      <Location />
    </div>
  );
};

export default Home;
