import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import productsData from "../data/products.json"; // Importe os dados do JSON

const ProductList = ({ category }) => {
  const products =
    category === "all"
      ? productsData
      : productsData.filter((product) => product.categories.includes(category));

  const productVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <div className="w-full bg-gray-200">
      <div className="container mx-auto px-6 py-12">
        <h1 className="text-3xl text-[#951514] font-bold mb-4">
          {products[0].categories.join("/")}{" "}
        </h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {products.map((product, index) => (
            <motion.div
              key={product.name}
              className="bg-white shadow-lg rounded-lg p-6 flex flex-col items-center hover:bg-[#e8d3d3]"
              variants={productVariants}
              initial="hidden"
              animate="visible"
              transition={{ duration: 1.5, delay: index * 0.2 }}
            >
              <Link to={`/produto/${product.name}`} className="text-center">
                <div className="flex w-full justify-center">
                  <img
                    src={process.env.PUBLIC_URL + product.images[0]}
                    alt={product.name}
                    className="h-64 object-contain mb-4"
                  />
                </div>
                <div className="text-center flex flex-col flex-grow mb-4">
                  <h2 className="mt-2 text-xl font-bold text-black">
                    {product.name}
                  </h2>
                  <h3 className="text-sm text-gray-600 font-semibold">
                    {product.categories.join(", ")}
                  </h3>
                </div>
              </Link>
              <div className="grid grid-cols-1 w-full mt-auto">
                <div className="grid grid-cols-1 w-full mt-auto">
                  <Link
                    to={`/produto/${product.name}`}
                    className="text-[#951514] font-bold hover:underline text-xl mt-1"
                  >
                    <button className="px-4 py-2 bg-[#951514] w-full text-white font-semibold rounded-md hover:bg-[#ac2a2a] transition duration-300">
                      + DETALHES
                    </button>
                  </Link>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductList;
