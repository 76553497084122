export default function AboutUs() {
  return (
    <div className="m-auto max-w-7xl bg-[#951514] p-4">
      <h1 className="text-white font-bold text-2xl pt-4 pb-6">
        Bem-vindo à ALPS LABOR!
      </h1>
      <p className="text-white mb-4">
        Fundada em 2021, a ALPS LABOR se dedica a fornecer equipamentos e
        soluções para a administração pública. Nossa equipe é formada por
        profissionais altamente qualificados, comprometidos em atender com
        eficiência e expertise as demandas dos nossos clientes.
      </p>
      <p className="text-white mb-4">
        Nossas principais atividades estão correlacionadas a comunicação,
        intercomunicação e radiocomunicação, primadas pelo rigor técnico,
        pontualidade e compromisso com a excelência. Esses valores norteiam
        nossas decisões e garantem a entrega de soluções e equipamentos
        consistentes e eficazes em todos os projetos.
      </p>
      <p className="text-white mb-4">
        Estamos atuando para estabelecer parcerias duradouras e oferecer
        resultados sólidos.
      </p>
      <p className="text-white mb-4">
        Agradecemos por escolher a ALPS LABOR como seu parceiro de confiança.
      </p>
    </div>
  );
}
