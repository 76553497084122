import React from "react";
import { VscRadioTower } from "react-icons/vsc";
import { MdOutlineConnectWithoutContact } from "react-icons/md";
import { MdSecurity } from "react-icons/md";
import { TbDeviceDesktopCog } from "react-icons/tb";

const infos = [
  {
    id: 1,
    title: "Comunicação Crítica",
    description: "Sistemas de comunicação de alta qualidade",
  },
  {
    id: 2,
    title: "Conectividade",
    description: "Soluções integradas de links de dados",
  },
  {
    id: 3,
    title: "Segurança",
    description: "Eficiência operacional e segurança de ponta",
  },
  {
    id: 4,
    title: "Gestão operacional",
    description: "Operações com monitoramento ativo em tempo real",
  },
];

export function InfoSection() {
  return (
    <div className="container m-auto mb-0 px-6 py-12 ">
      <h1 className="text-3xl font-bold text-[#951514] text-center mb-8">
        Portfólio de soluções tecnológicas a serviço da vida.
      </h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4">
        {infos.map((info) => (
          <div
            key={info.id}
            className="shadow-none rounded-none p-6 flex flex-col items-center"
          >
            {info.id === 1 ? (
              <VscRadioTower size={150} color="gray" className="mb-4" />
            ) : info.id === 2 ? (
              <MdOutlineConnectWithoutContact
                size={150}
                color="gray"
                className="mb-4"
              />
            ) : info.id === 3 ? (
              <MdSecurity size={150} color="gray" className="mb-4" />
            ) : (
              <TbDeviceDesktopCog size={150} color="gray" className="mb-4" />
            )}
            <div className="text-center">
              <h2 className="mt-2 text-lg font-bold text-[#951514]">
                {info.title}
              </h2>
              <h3 className="text-sm text-black font-semibold">
                {info.description}
              </h3>
              {/* <h4 className="text-xs text-gray-500">{info.type}</h4>
              <button className="mt-4 text-teal-600 font-semibold hover:underline">
                {info.buttonText}
              </button> */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
