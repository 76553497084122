import React from "react";
import { Link } from "react-router-dom";
import { FaWhatsapp } from "react-icons/fa";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";

const Contacts = () => {
  return (
    <div className="w-full bg-gray-200 pt-8 pb-8">
      <div className="max-w-[83em] mx-auto mb-28 p-4 space-y-4">
        <h1 className="text-4xl font-bold text-[#951514] mt-14 mb-10 flex justify-center">
          Central de Contato
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <a
            href="https://wa.me/+5561983054066"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-white rounded-lg shadow-md overflow-hidden flex flex-col items-center p-4 transition duration-300 ease-in-out hover:bg-[#e8d3d3] cursor-pointer"
          >
            <FaWhatsapp size={200} color="gray" className="mb-4" />

            <h2 className="text-xl font-semibold text-[#951514] text-center mb-2">
              Atendimento online - WhatsApp
            </h2>
            <p className="text-gray-600 text-center">
              De segunda a sexta das 09h às 12h e das 13h às 17h
            </p>
          </a>

          <Link
            to="/orcamento"
            className="bg-white rounded-lg shadow-md overflow-hidden flex flex-col items-center p-4 transition duration-300 ease-in-out hover:bg-[#e8d3d3] cursor-pointer"
          >
            <FaFileInvoiceDollar size={200} color="gray" className="mb-4" />

            <h2 className="text-xl font-semibold text-[#951514] text-center mb-2">
              Solicite Orçamento
            </h2>
            <p className="text-gray-600 text-center">Produtos e Soluções</p>
          </Link>

          <Link
            to="/contato"
            className="bg-white rounded-lg shadow-md overflow-hidden flex flex-col items-center p-4 transition duration-300 ease-in-out hover:bg-[#e8d3d3] cursor-pointer"
          >
            <MdOutlineEmail size={200} color="gray" className="mb-4" />

            <h2 className="text-xl font-semibold text-[#951514] text-center mb-2">
              Contato
            </h2>
            <p className="text-gray-600 text-center">
              Preencha as informações abaixo com dúvidas, críticas e sugestões
            </p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Contacts;
