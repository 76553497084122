import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const CategoryCards = () => {
  const cardVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <div className="w-full bg-gray-200 py-8">
      <div className="container mx-auto px-4 py-8">
        <motion.h1
          className="text-4xl text-[#951514] font-bold mb-4"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1.5 }}
        >
          Categorias de Produtos
        </motion.h1>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <motion.div
            className="bg-white p-6 rounded-lg shadow-md"
            variants={cardVariants}
            initial="hidden"
            animate="visible"
            transition={{ duration: 1.5, delay: 0.2 }}
          >
            <img
              src={"/assets/images/banner-dmr.png"}
              alt={"DMR"}
              className="w-fit object-contain mb-4"
            />
            <h2 className="text-2xl font-semibold mb-2">DMR</h2>
            <p className="text-gray-600 mb-4">
              Produtos de Radiocomunicação DMR.
            </p>
            <div className="flex justify-end">
              <Link to="/produtos/DMR">
                <button className="px-8 py-4 bg-[#951514] text-white font-semibold rounded-md hover:bg-[#ac2a2a] transition duration-300">
                  VER TODOS
                </button>
              </Link>
            </div>
          </motion.div>
          <motion.div
            className="bg-white p-6 rounded-lg shadow-md"
            variants={cardVariants}
            initial="hidden"
            animate="visible"
            transition={{ duration: 1.5, delay: 0.4 }}
          >
            <img
              src={"/assets/images/banner-p25.png"}
              alt={"P25"}
              className="w-fit object-contain mb-4"
            />
            <h2 className="text-2xl font-semibold mb-2">P25</h2>
            <p className="text-gray-600 mb-4">
              Produtos de Radiocomunicação P25.
            </p>
            <div className="flex justify-end">
              <Link to="/produtos/P25">
                <button className="px-8 py-4 bg-[#951514] text-white font-semibold rounded-md hover:bg-[#ac2a2a] transition duration-300">
                  VER TODOS
                </button>
              </Link>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default CategoryCards;
