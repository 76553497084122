import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import products from "../data/products.json";
import { ProductDetailsGallery } from "../components/ProductDetailsGallery";

export default function ProductPage() {
  const { productName } = useParams();
  const navigate = useNavigate();
  const product = products.find(
    (p) => p.name === decodeURIComponent(productName)
  );

  if (!product) {
    return <div>Produto não encontrado</div>;
  }

  // Dividir a descrição em parágrafos
  const descriptionParagraphs = product.description.split("\n\n");
  // Dividir o nome do produto e pegar as duas últimas partes
  const nameParts = product.name.split(" ");
  const lastPart = nameParts.pop();
  const secondLastPart = nameParts.pop();
  const initialPart = nameParts.join(" ");

  const handleOrcamentoClick = () => {
    navigate("/orcamento", { state: { product } });
  };

  return (
    <div className="bg-gray-100 min-h-screen">
      {/* Main content */}
      <motion.div
        initial={{ opacity: 0, translateY: 50 }}
        animate={{ opacity: 1, translateY: 0 }}
        transition={{ duration: 0.5, ease: "easeInOut" }}
        className="container mx-auto px-4 py-8"
      >
        <div className="bg-white rounded-lg shadow-lg p-8">
          <div className="flex flex-col md:flex-row">
            {/* Left column */}
            <motion.div
              initial={{ opacity: 0, translateY: 50 }}
              animate={{ opacity: 1, translateY: 0 }}
              transition={{ duration: 0.5, ease: "easeInOut", delay: 0.2 }}
              className="md:w-2/3 pr-8"
            >
              <h1 className="text-4xl font-bold mb-2">
                {initialPart}{" "}
                <span className="text-[#951514]">
                  {secondLastPart} {lastPart}
                </span>
              </h1>
              <h2 className="text-xl mb-4">{product.subtitle}</h2>
              {descriptionParagraphs.map((paragraph, index) => (
                <p key={index} className="text-gray-600 mb-6">
                  {paragraph}
                </p>
              ))}
              <button
                onClick={handleOrcamentoClick}
                className="px-8 py-4 bg-[#951514] text-white font-semibold rounded-md hover:bg-[#ac2a2a] transition duration-300"
              >
                SOLICITE UM ORÇAMENTO
              </button>
            </motion.div>

            {/* Right column */}
            <motion.div
              initial={{ opacity: 0, translateY: 50 }}
              animate={{ opacity: 1, translateY: 0 }}
              transition={{ duration: 0.5, ease: "easeInOut", delay: 0.4 }}
              className="md:w-1/3 mt-6 md:mt-0"
            >
              <ProductDetailsGallery images={product.images} />
            </motion.div>
          </div>
        </div>
      </motion.div>
    </div>
  );
}