import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import { NavbarWithMegaMenu } from "./components/Navbar";
import { Footer } from "./components/Footer";
import Home from "./pages/Home";
import ProductPage from "./pages/ProductPage";
import Contacts from "./pages/Contatcts";
import Contato from "./pages/Contato";
import Orcamento from "./pages/Orcamento";
import CategoryCards from "./pages/CategoryCards";
import ProductList from "./components/ProductsList";
import WhatsappWidget from "./components/WhatsappWidget";

function App() {
  return (
    <Router>
      <NavbarWithMegaMenu />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/produtos" element={<CategoryCards />} />
        <Route path="/produtos/DMR" element={<ProductList category="DMR" />} />
        <Route path="/produtos/P25" element={<ProductList category="P25" />} />
        <Route path="/produto/:productName" element={<ProductPage />} />
        <Route path="/contatos" element={<Contacts />} />
        <Route path="/contato" element={<Contato />} />
        <Route path="/orcamento" element={<Orcamento />} />
      </Routes>
      <Footer />
      <WhatsappWidget />
    </Router>
  );
}

export default App;
